<template>
  <div class="home">
      
      <!-- Active Competitions -->

      <section class="container">
            <!-- <h1 class="mt-5 mb-5">Active Competitions</h1> -->

            <div class="row" v-if="listings.length > 0">
              <CompetitionListing v-bind:account="account" v-for="listing in listings" v-bind:listing="listing"  v-bind:key="listing.id"/>
            </div>
            <div class="row" v-else>
              <h1 className="text-center mt-3">No active competitions.</h1>
            </div>
      </section>

  </div>

  <Footer />

</template>

<script>
import Api from '../scripts/api'
import Footer from '../components/global/Footer'
import CompetitionListing from '../components/home/CompetitionListing'
export default {
    components: {
        CompetitionListing,
        Footer
    },
    props: {
      account: Object
    },
    data() {
      return {
        listings: []
      }
    },
    async created() {
      var result = await Api.competition.listActive();
      if(result.status === 200) {
        this.listings = await result.json();
      }
      else {
        this.$toast.error('Could not retrieve listings.')
      }
    }
}
</script>
